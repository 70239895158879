exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-about-js": () => import("./../../../src/pages/contact-about.js" /* webpackChunkName: "component---src-pages-contact-about-js" */),
  "component---src-pages-featured-work-js": () => import("./../../../src/pages/featured-work.js" /* webpackChunkName: "component---src-pages-featured-work-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-portfolio-entry-js": () => import("./../../../src/templates/portfolio-entry.js" /* webpackChunkName: "component---src-templates-portfolio-entry-js" */),
  "slice---src-components-contact-icons-js": () => import("./../../../src/components/contact-icons.js" /* webpackChunkName: "slice---src-components-contact-icons-js" */),
  "slice---src-components-contact-text-js": () => import("./../../../src/components/contact-text.js" /* webpackChunkName: "slice---src-components-contact-text-js" */),
  "slice---src-components-footer-js": () => import("./../../../src/components/footer.js" /* webpackChunkName: "slice---src-components-footer-js" */),
  "slice---src-components-header-js": () => import("./../../../src/components/header.js" /* webpackChunkName: "slice---src-components-header-js" */)
}

